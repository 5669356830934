import {
  ErrorBoundary,
  Facet,
  Paging,
  PagingInfo,
  ResultsPerPage,
  SearchBox,
  SearchProvider,
  Sorting,
  WithSearch,
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  Box,
  Button,
  Card,
  CardTitle,
  Flex,
  Image,
  Key,
  PageWithHeader,
  Provider as BumbagProvider,
  Tag,
  TopNav,
  Alert,
  Link,
} from "bumbag";
import * as dayjs from "dayjs";
import React, { useEffect } from "react";
import JSONPretty from "react-json-pretty";
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields,
} from "./config/config-helper";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase,
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig(),
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true,
};

export default function App() {
  const [User, setUser] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/.auth/me");
      const payload = await response.json();
      const { clientPrincipal } = payload;

      if (clientPrincipal === null) {
        window.location.pathname = "/.auth/login/aad";
      } else {
        setUser(clientPrincipal);
      }
    }
    fetchData();
    /* setTimeout(() => {
      document.querySelector("input[type=submit]").value = "Arama";
      console.log("sa")
    }, 3000); */
  }, []); // Or [] if effect doesn't need props or state

  /* useEffect(() => {
    if (window.location.pathname != "/?size=n_20_n&sort-field=createdat&sort-direction=desc") {
      window.location.pathname = "/?size=n_20_n&sort-field=createdat&sort-direction=desc";
    }
  }, [User])
 */

  const Discriminator = {
    SelectItemForPackingLog: "paketleme işlemi içerisinde ürün okutma",
    PlaceProductFromCellToToteLog:
      "stok gözünden toplama sepetine ürün aktarma",
    CompletePackingLog: "paketleme işlemini tamamlama",
    CompleteDispatchLog: "sevkiyat işlemini tamamlama",
    CompleteSLAMLog: "koli etiketleme işlemini tamamlama",
    PrioritizeSalesOrderLog: "sipariş önceliklendirme",
    PlaceProductToCellLog: "sepetten stok gözüne ürün yerleştirme",
    PlaceReceivedItemLog:
      "mal kabul kolisinden problemsiz sepetine ürün aktarma",
    ReturnItemsPlacedLog: "ürün iadesi kabul etme",
    LostItemAtPackingLog:
      "paketlenecek ürünü toplama sepetinde bulamadığını bildirme",
    ReturnPackagePlacedOnTrolleyLog: "iade kolisini depoya kabul etme",
    AddInboundBoxLog: "ön mal kabul kolisini depoya kabul etme",
    ProblemAtSLAMLog: "problemli koli etiketleme işlemini tamamlama",
    LostItemAtPickingLog: "toplanacak ürünü stok gözünde bulamadığını bildirme",
    RemovePriorityFromSalesOrderLog: "sipariş önceliğini kaldırma",
    CompletePutAwayLog: "yerleştirme aracını park alanına bırakma",
    SelectToteForSingleItemPackingLog: "arabaya toplama sepeti ekleme",
    PlaceToteToTrolleyLog: "arabaya yerleştirme sepeti ekleme",
    SelectReceivingToteLog:
      "ürün kabul sürecinde kullanılacak problemsiz sepetini ekleme",
    SelectQuarantineToteLog:
      "ürün kabul sürecinde kullanılacak karantina sepetini ekleme",
    SRCCountingCompletedLog: "ürün sayım ",
    LostItemAtPutAwayLog:
      "yerleştirilecek ürünü yerleştirme sepetinde bulamadığını bildirme",
    CreatePurchaseOrderLog: "mal kabul siparişi yaratma",
    EmailSentLog: "e-mail gönderme",
    TransferQuarantineToValidLog: "karantinadan problemsize ürün aktarma",
    RemoveCargoPackageFromDispatchLog: "sevkiyat işleminden koli çıkarma",
    TransferFromCellToCellLog: "stok gözünden stok gözüne ürün transfer",
    SoftAllocationFailedLog: "sipariş için yeterli stok bulamama",
    TransferPackingItemToToteLog:
      "iptal edilen siparişin kolisinden sepete ürün aktarma",
    PutItemsToToteForCancelledLog:
      "iptal edilen siparişin ürünlerini sepete aktarma",
    PlaceDamagedItemLog: "ürün kabul sürecinde karantina ürününü sepete ekleme",
  };

  // regular action list, rest is unexpected or less expected actions
  const DiscriminatorType = [
    "SelectItemForPackingLog",
    "PlaceProductFromCellToToteLog",
    "CompletePackingLog",
    "CompleteDispatchLog",
    "CompleteSLAMLog",
    "PrioritizeSalesOrderLog",
    "PlaceProductToCellLog",
    "PlaceReceivedItemLog",
    "ReturnItemsPlacedLog",
    "ReturnPackagePlacedOnTrolleyLog",
    "AddInboundBoxLog",
    "CompletePutAwayLog",
    "SelectToteForSingleItemPackingLog",
    "PlaceToteToTrolleyLog",
    "SelectReceivingToteLog",
    "SelectQuarantineToteLog",
    "SRCCountingCompletedLog",
  ];
  // actions' stock effect
  const DiscriminatorType2 = {
    SelectItemForPackingLog: "neutral",
    PlaceProductFromCellToToteLog: "neutral",
    CompletePackingLog: "neutral",
    CompleteDispatchLog: "neutral",
    CompleteSLAMLog: "neutral",
    PrioritizeSalesOrderLog: "neutral",
    PlaceProductToCellLog: "neutral",
    PlaceReceivedItemLog: "positive",
    ReturnItemsPlacedLog: "positive",
    LostItemAtPackingLog: "negative",
    ReturnPackagePlacedOnTrolleyLog: "neutral",
    AddInboundBoxLog: "neutral",
    ProblemAtSLAMLog: "neutral",
    LostItemAtPickingLog: "negative",
    RemovePriorityFromSalesOrderLog: "neutral",
    CompletePutAwayLog: "neurtal",
    SelectToteForSingleItemPackingLog: "neutral",
    PlaceToteToTrolleyLog: "neutral",
    SelectReceivingToteLog: "neutral",
    SelectQuarantineToteLog: "neutral",
    SRCCountingCompletedLog: "neutral",
    LostItemAtPutAwayLog: "negative",
    CreatePurchaseOrderLog: "neutral",
    EmailSentLog: "neutral",
    TransferQuarantineToValidLog: "positive",
    RemoveCargoPackageFromDispatchLog: "neutral",
    TransferFromCellToCellLog: "neutral",
    SoftAllocationFailedLog: "neutral",
    TransferPackingItemToToteLog: "positive",
    PutItemsToToteForCancelledLog: "positive",
    PlaceDamagedItemLog: "neutral",
  };
  console.log(DiscriminatorType);

  const Domain = {
    Picking: "Toplama",
    Packing: "Paketleme",
    Dispatch: "Sevkiyat",
    SLAM: "Etiketleme",
    SalesOrder: "Sipariş",
    Putaway: "Yerleştirme",
    Receiving: "Mal Kabul",
    Return: "İade",
    StockCountingTask: "Sayım",
    Problem: "Problem",
    Transfer: "Transfer",
  };

  return (
    <BumbagProvider>
      <SearchProvider config={config}>
        <WithSearch
          mapContextToProps={({ wasSearched, ...result }) => ({
            wasSearched,
            result,
          })}
        >
          {(data) => {
            console.log(data);

            return (
              <div className="App">
                <ErrorBoundary>
                  <PageWithHeader
                    header={
                      <TopNav>
                        <TopNav.Section>
                          <TopNav.Item
                            href="https://search.oplog.app"
                            fontWeight="semibold"
                          >
                            <Image
                              src="https://www.oplog.io/hubfs/website/logo.png"
                              height="25px"
                            />
                          </TopNav.Item>
                        </TopNav.Section>
                        <TopNav.Section marginRight="major-2">
                          <TopNav.Item>
                            <Button variant="ghost">
                              {User !== null ? User.userDetails : ""}
                            </Button>
                          </TopNav.Item>
                          <TopNav.Item>
                            <Button
                              palette="gray"
                              onClick={() => {
                                window.location.pathname = "/.auth/logout";
                              }}
                            >
                              Çıkış Yap
                            </Button>
                          </TopNav.Item>
                        </TopNav.Section>
                      </TopNav>
                    }
                    border="default"
                  >
                    <Alert
                      variant="tint"
                      showCloseButton="true"
                      onClickClose={() =>
                        (document.querySelector(
                          "#root > div > div > div > div.css-63it0p.css-1qnz8fc.css-z4x4ez.bb-PageWithHeaderContent.bb-Box > div.css-1tqzpvg.css-12n2nq2.css-63it0p.css-1w0fgar.bb-Box.bb-Flex.bb-Alert"
                        ).style.display = "none")
                      }
                      title="Aradığını bulamadın mı?"
                      type="info"
                      marginTop={0}
                    >
                      Depo içi hareket geçmişi arama uygulamasında bulamadığın
                      bilgileri{" "}
                      <Link color="#1e67d5" href="mailto:pmo@oplog.com.tr">
                        pmo@oplog.com.tr
                      </Link>{" "}
                      adresine gönder ve uygulamanın gelişmesine katkıda bulun.{" "}
                      <br></br>
                      Aşağıdaki arama çubuğuna aramak istediğin kelimeyi yazarak
                      başlayabilirsin.
                    </Alert>
                    <Layout
                      header={
                        <SearchBox
                          autocompleteSuggestions={true}
                          inputProps={{
                            placeholder:
                              "Aramaya başlamak için bir değer giriniz.",
                          }}
                        />
                      }
                      sideContent={
                        <div>
                          {data.wasSearched && (
                            <Sorting
                              label={"Sıralama"}
                              sortOptions={buildSortOptionsFromConfig()}
                            />
                          )}
                          {getFacetFields().map((field) => (
                            <Facet key={field} field={field} label={field} />
                          ))}
                        </div>
                      }
                      bodyContent={
                        <>
                          {data.result.results.map((item) => {
                            /*  let a = Object.keys(item).filter(
                                (x) =>
                                  x.search("domainproperties") === -1 &&
                                  x !== "operation"&&
                                  x !== "logtype"&&
                                  x !== "discriminator"&&
                                  x !== "_meta"&&
                                  x !== "userfullname"
                              ); */
                            console.log(
                              Discriminator[item["logtype"]?.raw.split(".")[3]]
                            );

                            /*console.log(
                              item[
                                item["domain"].raw.toLowerCase() +
                                  "domainproperties"
                              ]
                            ); */

                            return (
                              <Card width={"100%"} marginTop={10}>
                                <CardTitle
                                  width={"100%"}
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  marginBottom={2}
                                >
                                  <Flex
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  >
                                    <Tag
                                      palette="text"
                                      variant="tint"
                                      marginRight={2}
                                    >
                                      {item["userfullname"]?.raw
                                        ? item["userfullname"]?.raw
                                        : "Sistem"}
                                    </Tag>
                                    <Flex
                                      fontSize={"13px"}
                                      alignItems={"center"}
                                    >
                                      adlı kullanıcı
                                      <Key marginX={2}>
                                        {Domain[item["domain"]?.raw]
                                          ? Domain[item["domain"]?.raw]
                                          : item["domain"]?.raw}
                                      </Key>
                                      alanında
                                      <Key marginX={2}>
                                        {Discriminator[
                                          item["logtype"]?.raw.split(".")[3]
                                        ]
                                          ? Discriminator[
                                              item["logtype"]?.raw.split(".")[3]
                                            ]
                                          : item["logtype"]?.raw.split(".")[3]}
                                      </Key>
                                      işlemi yapmıştır.
                                    </Flex>
                                  </Flex>
                                  <Box>
                                    <Tag>
                                      {dayjs(item["createdat"]?.raw).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </Tag>
                                  </Box>
                                </CardTitle>
                                <Flex width={"100%"}>
                                  {/*  <Table width="50%"marginRight="20px">
                                      <Table.Head>
                                        <Table.Row>
                                          <Table.HeadCell>Field</Table.HeadCell>
                                          <Table.HeadCell>Value</Table.HeadCell>
                                        </Table.Row>
                                      </Table.Head>
                                      <Table.Body>
                                        {a.map((i) => {
                                          return (
                                            <Table.Row>
                                              <Table.Cell fontSize="13px">
                                                {i}
                                              </Table.Cell>
                                              <Table.Cell fontSize="13px">
                                                {i === "createdat"
                                                  ? dayjs(item[i].raw).format(
                                                    "DD/MM/YYYY HH:mm"
                                                    )
                                                  : item[i].raw}
                                              </Table.Cell>
                                            </Table.Row>
                                          );
                                        })}
                                      </Table.Body>
                                    </Table> */}
                                  <Card fontSize={"14px"} width={"100%"}>
                                    <Flex justifyContent="end" gap="15px">
                                      {/* {item[
                                        item["domain"].raw.toLowerCase() +
                                          "domainproperties"
                                      ]["amount"] !== null &&
                                      item[
                                        item["domain"].raw.toLowerCase() +
                                          "domainproperties"
                                      ]["amount"] > 0 ? (
                                        <Tag
                                          isAttached
                                          palette="warning"
                                          width="auto"
                                        >
                                          Çoklu İşlem
                                        </Tag>
                                      ) : (
                                        ""
                                      )} */}

                                      {DiscriminatorType2[
                                        item["logtype"]?.raw.split(".")[3]
                                      ] === "positive" ? (
                                        <Tag
                                          isAttached
                                          palette="successTint"
                                          width="auto"
                                        >
                                          Stok Artıran İşlem
                                        </Tag>
                                      ) : DiscriminatorType2[
                                          item["logtype"]?.raw.split(".")[3]
                                        ] === "negative" ? (
                                        <Tag
                                          isAttached
                                          palette="dangerTint"
                                          width="auto"
                                        >
                                          Stok Azaltan İşlem
                                        </Tag>
                                      ) : (
                                        ""
                                      )}

                                      {DiscriminatorType.includes(
                                        item["logtype"]?.raw.split(".")[3]
                                      ) ? (
                                        ""
                                      ) : (
                                        <Tag
                                          isAttached
                                          palette="danger"
                                          width="auto"
                                        >
                                          Olağandışı İşlem
                                        </Tag>
                                      )}
                                    </Flex>
                                    <JSONPretty
                                      id="json-pretty"
                                      data={
                                        item[
                                          item["domain"].raw.toLowerCase() +
                                            "domainproperties"
                                        ].raw
                                      }
                                    ></JSONPretty>
                                  </Card>
                                </Flex>
                              </Card>
                            );
                          })}
                          {/* <Results
                              titleField={getConfig().titleField}
                              urlField={getConfig().urlField}
                              thumbnailField={getConfig().thumbnailField}
                              shouldTrackClickThrough={true}
                            /> */}
                        </>
                      }
                      bodyHeader={
                        <React.Fragment>
                          {data.wasSearched && <PagingInfo />}
                          {data.wasSearched && <ResultsPerPage />}
                        </React.Fragment>
                      }
                      bodyFooter={<Paging />}
                    />
                  </PageWithHeader>
                </ErrorBoundary>
              </div>
            );
          }}
        </WithSearch>
      </SearchProvider>
    </BumbagProvider>
  );
}
